import { Container, Copy } from '@raylo-tech/raylopay-ui';
import { px2Rem } from '../../utils/px2Rem';
import { IIneligiblePaymentBox } from './types';
import { StyledIneligiblePaymentBox } from './styles';

const IneligiblePaymentBox = (
  {
    dataTestId,
    label,
    formattedMonthlyPrice,
  }: IIneligiblePaymentBox) => {
  return (
    <StyledIneligiblePaymentBox
      data-testid={dataTestId}
    >
      <Container
        flexRow
        width="100%"
        padding={`0 ${px2Rem(24)}`}
      >
        <Container padding={`0 ${px2Rem(8)} 0 0`} centerVertical width="fit-content">
          <Container>
            <Copy
              fontSize={14}
              styles={{ whiteSpace: 'nowrap' }}
            >
              {label}
            </Copy>
          </Container>
          <Container padding={`${px2Rem(8)} 0 0 0`}>
            <Copy fontSize={12}>Exceeds limit</Copy>
          </Container>
        </Container>
        <Container alignRight noPadding width="fit-content" margin="0 0 0 auto" centerVertical>
          <Container flexRow alignRight centerVertical>
            <Copy fontSize={18} bold>{formattedMonthlyPrice}</Copy>
            <Copy fontSize={14}>/mo</Copy>
          </Container>
        </Container>
      </Container>
    </StyledIneligiblePaymentBox>
  );
};

export default IneligiblePaymentBox;
