/**
 * Removes duplicate elements from an array and returns a new array with unique elements.
 *
 * @param array - The array from which to remove duplicate elements.
 * @returns A new array with unique elements.
 * @template T - The type of elements in the array.
 */
export const unique = <T extends string | number>(array: T[]): T[] => {
  return Array.from(new Set(array));
};

type Comparator<T> = (a: T, b: T) => number;

// https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore/issues/280#issuecomment-690604745
const sortBy = <T>(key: keyof T, cb?: Comparator<T>): Comparator<T> => {
  if (!cb) {
    cb = () => 0;
  }
  return (a, b) => (a[key] > b[key]) ? 1 :
      ((b[key] > a[key]) ? -1 : cb!(a, b));
};

// https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore/issues/280#issuecomment-690604745
const sortByDesc = <T>(key: keyof T, cb?: Comparator<T>): Comparator<T> => {
  if (!cb) {
    cb = () => 0;
  }
  return (b, a) => (a[key] > b[key]) ? 1 : ((b[key] > a[key]) ? -1 : cb!(b, a));
};

/**
 * Creates a comparator function for ordering an array of objects based on the specified keys and orders.
 *
 * https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore/issues/280#issuecomment-690604745
 *
 * @template T - The type of the objects in the array.
 * @param {Array<keyof T>} keys - An array of keys to order the objects by.
 * @param {Array<'asc' | 'desc'>} orders - An array of orders corresponding to the keys.
 * @returns {Comparator<T>} - A comparator function that can be used to sort an array of objects.
 * @throws {Error} - If an unsupported order is provided.
 */
export const orderBy = <T>(keys: (keyof T)[], orders: ('asc' | 'desc')[]): Comparator<T> => {
  let cb: Comparator<T> = () => 0;
  keys.reverse();
  orders.reverse();
  for (const [i, key] of keys.entries()) {
    const order = orders[i];
    if (order === 'asc') {
      cb = sortBy(key, cb);
    } else if (order === 'desc') {
      cb = sortByDesc(key, cb);
    } else {
      throw new Error(`Unsupported order "${order}"`);
    }
  }
  return cb;
};

/**
 * Returns a random element from the given array.
 *
 * @template T - The type of elements in the array.
 * @param {T[]} arr - The array from which to select a random element.
 * @returns {T} - A random element from the array.
 */
export const sample = <T>(arr: T[]): T => {
  return arr[Math.floor(Math.random() * arr.length)];
};
