import Icon from '../../elements/Icon';
import Container from '../../elements/Container';
import { px2Rem } from '../../utils/px2Rem';
import { primaryColors, secondaryColors } from '../../styles/variables/colours';
import Copy from '../../elements/Copy';
import { StyledSpan } from '@raylo-tech/raylopay-ui';

const PreApprovedAmountBanner = ({
  formattedAmount,
  shortVersion = false,
}: {
  formattedAmount: string;
  shortVersion?: boolean;
}) => (
  <Container
    flexRow
    height={px2Rem(24)}
    justifyCenter
    styles={{ justifyContent: 'flex-start' }}
    dataTestId="pre-approved-amount-banner"
  >
    <Container width="fit-content">
      <Icon name="PreApproved" size={px2Rem(24)} fill={secondaryColors.b02} dataTestId="pre-approved-amount-banner-icon" />
    </Container>
    <Container width="fit-content" padding={`0 0 0 ${px2Rem(8)}`}>
      <Copy fontSize={14} lineHeight={24} color={primaryColors.c01}>
        {shortVersion ? 'Pre-approved' : 'Available pre-approved limit'}:&nbsp;<StyledSpan boldFont color={primaryColors.c01}>{formattedAmount}/mo</StyledSpan>
      </Copy>
    </Container>
  </Container>
);

export default PreApprovedAmountBanner;
