import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useAppContext } from './useAppContext';

/**
 * Close the modal when a client-side navigation occurs. This hook should only
 * be included in modals which are dependant on content from a context provider
 * which is only available on that page
 */
export const useCloseModalOnRouterEvent = (enabled: boolean) => {
  const router = useRouter();
  const { modalOpen, setModalOpen } = useAppContext();

  useEffect(() => {
    const routeChangeStart = () => {
      /**
       * Close the modal if the route changes. Some modals depend on content which comes
       * from a context provider. If a modal is open and a user goes back in their
       * browser, it can cause an error as the content is no longer available
       */
      if (enabled && modalOpen) {
        setModalOpen(null);
      }
    };
    router.events.on('routeChangeStart', routeChangeStart);

    return () => {
      router.events.off('routeChangeStart', routeChangeStart);
    };
  }, [router.events, modalOpen, setModalOpen, enabled]);
};
