import { CostSummary, CustomerVariantCostSummary, VariantForPLP } from '../types/productTypes';

export const updateCostSummaries = (products: VariantForPLP[], lowestCostSummaries: CustomerVariantCostSummary[]) => {
  const productsWithPricing = products.map((product) => {
    const pricing = lowestCostSummaries.find(({ id }) => id === product.variantId);

    if (!pricing?.lowestCostSummary) {
      return product;
    }

    const lowestMonthlyCost = pricing.lowestCostSummary.recurring.totalAmount.valueAfterTax.value;
    return {
      ...product,
      lowestMonthlyCost,
    };
  });

  return productsWithPricing;
};

type CostSummaryRiskBasedPricingSubset = {
  id: string;
  recurring: {
    totalAmount: {
      valueAfterTax: {
        value: number;
      };
    };
  };
};

export const updateAllVariantsCostSummaries = (
  costSummaries: CostSummary[],
  riskBasedCostSummaries: CostSummaryRiskBasedPricingSubset[]
) => {
  return costSummaries.map((costSummary) => {
    const riskBasedCostSummary = riskBasedCostSummaries.find(riskBasedSummary => riskBasedSummary.id === costSummary.id);
    const newTotalAmount = riskBasedCostSummary?.recurring.totalAmount.valueAfterTax.value;

    if (!newTotalAmount) return costSummary;

    return {
      ...costSummary,
      recurring: {
        ...costSummary.recurring,
        totalAmount: {
          ...costSummary.recurring.totalAmount,
          valueAfterTax: {
            ...costSummary.recurring.totalAmount.valueAfterTax,
            value: newTotalAmount,
          },
        },
      },
    };
  });
};
