import { ReactNode } from 'react';
import { StyledPreApprovedBadgeBody, StyledPreApprovedBadgeContainer, StyledPreApprovedBadgeFold } from './styles';
import { PreApprovedBadge } from './index';

const PreApprovedBadgeContainer = ({
  dataTestId,
  children,
}: {
  dataTestId?: string;
  children: ReactNode;
}) => (
  <StyledPreApprovedBadgeContainer data-testid={dataTestId}>
    <StyledPreApprovedBadgeBody>
      <PreApprovedBadge />
    </StyledPreApprovedBadgeBody>
    <StyledPreApprovedBadgeFold />
    {children}
  </StyledPreApprovedBadgeContainer>
);

export default PreApprovedBadgeContainer;
