import React from 'react';
import { StyledSkeletonLoadingBlock } from './SkeletonLoadingBlock.styles';
import { SkeletonLoadingBlockProps } from './SkeletonLoadingBlock.types';

export const SkeletonLoadingBlock = ({ dataTestId, $width, $height, $borderRadius }: SkeletonLoadingBlockProps) => (
  <StyledSkeletonLoadingBlock
    data-testid={dataTestId}
    $height={$height}
    $width={$width}
    $borderRadius={$borderRadius}
  />
);
