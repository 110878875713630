import { getCookie } from '../../utils/browserStorage';

const sessionInfo = () => {
  const measurementCookieValues = getCookie(`_ga_${process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID}`)?.split('.');
  const analyticsCookieValues = getCookie('_ga')?.split('.');
  if (!measurementCookieValues || !analyticsCookieValues) {
    return { clientId: undefined, sessionId: undefined };
  }

  const sessionId = measurementCookieValues[2];
  const clientId = `${analyticsCookieValues[2]}.${analyticsCookieValues[3]}`;
  return { clientId, sessionId };
};

export { sessionInfo };
