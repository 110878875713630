import { Copy, RayloModal, SectionMessageBase, Spacer, StyledSpan } from '@raylo-tech/raylopay-ui';
import { useAppContext } from '../../hooks/useAppContext';
import { IAddTechModal } from './types';
import { secondaryColors } from '../../styles/variables/colours';
import { useProductsContext } from '../../hooks/useProductsContext';
import { useCloseModalOnRouterEvent } from '@/hooks/useCloseModalOnRouterEvent';

const AboutYourLimitModal = ({
  dataTestId,
  formattedAmount,
}: IAddTechModal) => {
  const { modalOpen, setModalOpen } = useAppContext();
  const { filters, toggleFilters } = useProductsContext();

  useCloseModalOnRouterEvent(modalOpen === 'aboutYourLimit');

  const preApprovedFilterEnabled = !!filters?.monthlyprice?.find(filter => filter?.toLowerCase() === 'pre-approved');

  return (
    <RayloModal
      modalOpen={modalOpen === 'aboutYourLimit'}
      dataTestId={dataTestId}
      handleCloseModal={() => setModalOpen(null)}
      primaryButtonOnClick={() => setModalOpen(null)}
      primaryButtonText="OKAY"
      secondaryButtonText={preApprovedFilterEnabled ? undefined : 'FILTER BY PRE-APPROVED'}
      secondaryButtonOnClick={preApprovedFilterEnabled ? undefined : () => {
        toggleFilters('monthlyprice', 'pre-approved');
        setModalOpen(null);
      }}
      title="About your limit"
    >
      <Copy fontSize={16} lineHeight={20}>Your available pre-approved limit is <StyledSpan boldFont>{formattedAmount}/mo</StyledSpan></Copy>
      <Spacer height={12} />
      <Copy>This is the maximum you have been approved to spend on extra tech per month.</Copy>
      <Spacer height={12} />
      <SectionMessageBase header="Your limit is based on" bulletList={['How long you have been a customer', 'Your payment history']} />
      <Spacer height={12} />
      <Copy fontSize={14} color={secondaryColors.c02}>
        Your available pre-approved limit can fluctuate and is subject to checks.
      </Copy>
    </RayloModal>
  );
};

export default AboutYourLimitModal;
