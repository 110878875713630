import { useAppContext } from '../../hooks/useAppContext';
import PreApprovedAmountBanner from './PreApprovedAmountBanner';
import {
  StyledPreApprovedHeaderBanner,
  StyledPreApprovedHeaderBannerContent
} from './styles';
import { secondaryColors } from '../../styles/variables/colours';
import { px2Rem } from '../../utils/px2Rem';
import Icon from '../../elements/Icon';

const PreApprovedHeaderBanner = ({
  formattedAmount,
}: {
  formattedAmount: string;
}) => {
  const { setModalOpen } = useAppContext();

  return (
    <StyledPreApprovedHeaderBanner>
      <StyledPreApprovedHeaderBannerContent onClick={() => setModalOpen('aboutYourLimit')}>
        <PreApprovedAmountBanner formattedAmount={formattedAmount} />
        <Icon name="Info" fill={secondaryColors.c02} size={px2Rem(16)} />
      </StyledPreApprovedHeaderBannerContent>
    </StyledPreApprovedHeaderBanner>
  );
};

export default PreApprovedHeaderBanner;
