import { sessionInfo as googleAnalytics4Session } from '../googleAnalytics4/tracking';

// eslint-disable-next-line @typescript-eslint/ban-types
export const track = (eventName: string, properties: {}): void => {
  const { clientId, sessionId } = googleAnalytics4Session();
  window.analytics?.track(eventName,
    {
      ...properties,
      ga_client_id: clientId,
      ga_session_id: sessionId,
    }
  );
};
