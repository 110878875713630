import { Copy, RayloModal, SectionMessageBase, Spacer, StyledSpan } from '@raylo-tech/raylopay-ui';
import { useAppContext } from '../../hooks/useAppContext';
import { IAddTechModal } from './types';
import { secondaryColors } from '../../styles/variables/colours';
import { useProductsContext } from '../../hooks/useProductsContext';
import { useCloseModalOnRouterEvent } from '@/hooks/useCloseModalOnRouterEvent';

const NotApprovedDeviceModal = ({
  dataTestId,
  formattedAmount,
}: IAddTechModal) => {
  const { modalOpen, setModalOpen } = useAppContext();
  const { toggleFilters } = useProductsContext();

  useCloseModalOnRouterEvent(modalOpen === 'notApprovedDevice');

  return (
    <RayloModal
      modalOpen={modalOpen === 'notApprovedDevice'}
      dataTestId={dataTestId}
      handleCloseModal={() => setModalOpen(null)}
      title="Device not within your available pre-approved limit"
      primaryButtonText="OKAY"
      primaryButtonOnClick={() => setModalOpen(null)}
      secondaryButtonText="FILTER BY PRE-APPROVED"
      secondaryButtonOnClick={() => {
        toggleFilters('monthlyprice', 'pre-approved');
        setModalOpen(null);
      }}
    >
      <Copy fontSize={16} lineHeight={20}>The price of this device is not within your available pre-approved limit of <StyledSpan boldFont>{formattedAmount}/mo</StyledSpan></Copy>
      <Spacer height={12} />
      <Copy>This is the maximum you have been approved to spend on extra tech per month.</Copy>
      <Spacer height={12} />
      <SectionMessageBase header="Your limit is based on" bulletList={['How long you have been a customer', 'Your payment history']} />
      <Spacer height={12} />
      <Copy fontSize={14} color={secondaryColors.c02}>
        Your available pre-approved limit can fluctuate and is subject to checks.
      </Copy>
    </RayloModal>
  );
};

export default NotApprovedDeviceModal;
