import { FilterState, SortType, SortDirection, SortKey, SortOption } from '../../hooks/useProductsContext';
import pluralize from 'pluralize';
import { track } from '../../integrations/segment/segmentTracking';
import { VariantForPLP } from '../../types/productTypes';
import { VariantCondition } from '@/graphql/generated/graphql';
import { orderBy } from '@/utils/arrays.utils';

export const ITEM_CONDITIONS = ['NEW', 'REFURBISHED'];

export const ALL_SORT_OPTIONS: SortOption[] = [
  { label: 'Recommended', value: 'Recommended' },
  { label: 'Highest Price', value: 'Highest Price' },
  { label: 'Lowest Price', value: 'Lowest Price' },
];

const FILTER_ITEMS = Object.freeze({
  category: 'catDisplayName',
  condition: 'productVariantConditions',
  model: 'displayName',
  make: 'make',
  monthlyprice: 'monthlyprice',
});

export const getPriceRanges = (products: VariantForPLP[], eligible: boolean): string[] => {
  if (!products?.length) {
    return [];
  }
  const maxPrice = products.reduce((a, b) => (
    (a.lowestMonthlyCost ?? 0) > (b.lowestMonthlyCost ?? 0) ? a : b
  )).lowestMonthlyCost;

  const rounded = Math.ceil(maxPrice! / 10) * 10;
  const priceRanges = [];

  if (eligible) {
    priceRanges.push('Pre-approved');
  }

  for (let i = 10; i <= rounded; i += 10) {
    const range = `${i - 10} - ${i}`;
    priceRanges.push(range);
  }

  return priceRanges;
};

export const getPriceRangeCount = (products: VariantForPLP[], range: string, preApprovedAmount: number): string => {
  if (!products?.length) {
    return '';
  }
  let count = 0;
  if (range.toLowerCase() === 'pre-approved') {
    if (preApprovedAmount) {
      count = products.filter((p) => {
        return p.lowestMonthlyCost && p.lowestMonthlyCost && p.lowestMonthlyCost <= preApprovedAmount;
      }).length;
    }
    return `${range} (${count})`;
  }
  count = products.filter((p) => {
    return p.lowestMonthlyCost && checkProductIsInRange([range], p.lowestMonthlyCost);
  }).length;
  return `£${range} (${count})`;
};

export const checkProductIsInRange = (priceRanges: (string | null)[], price?: number | null) => {
  if (!price) {
    return false;
  }
  return priceRanges.some((range) => {
    if (!range) return true;
    const [low, high] = range.split('-');
    return parseInt(low) <= price && price < (parseInt(high) + 1);
  });
};

export const productIsIncludedInFilter = (
  filters: FilterState,
  prod: VariantForPLP,
  categories?: (keyof FilterState)[]
) => {
  const filterTypes = !categories || !categories.length ? Object.keys(FILTER_ITEMS) : categories;

  return filterTypes.every((filterType) => {
    const type = filterType as keyof FilterState;
    if (!filterTypes.includes(type)) return true;

    if (type === 'monthlyprice') {
      return !filters.monthlyprice.length ||
        (filters.monthlyprice.some((x: string | null) => x?.toLowerCase() === 'pre-approved') && prod.eligible) ||
        checkProductIsInRange(filters.monthlyprice.filter(p => p?.toLowerCase() !== 'pre-approved'), prod.lowestMonthlyCost);
    }

    if (type === 'condition') {
      return !filters.condition.length || filters.condition.some(c => prod.productVariantConditions.includes(c?.toUpperCase() as VariantCondition));
    }

    const keyToUse = type === 'category' ?
      pluralize(prod[FILTER_ITEMS[type]] ?? '') : prod[FILTER_ITEMS[type]];

    return !filters[type].length || isSelected(filters[type], keyToUse ?? '');
  });
};

export const filterProducts = (
  products: VariantForPLP[],
  filters: FilterState,
  filterTypes?: (keyof FilterState)[]
) => {
  const filtered = products.filter(prod => productIsIncludedInFilter(filters, prod, filterTypes));
  return filtered;
};

export const sortProductItems = (
  products: VariantForPLP[],
  sortType: SortType
) => {
  let order: SortDirection[] = ['asc'];
  let sortKey: SortKey[] = ['lowestMonthlyCost'];

  if (sortType === 'Highest Price') {
    order = ['desc'];
  }

  if (sortType === 'Recommended') {
    sortKey = ['eligible', 'position'];
    order = ['desc', 'asc'];
  }

  return [...products].sort(orderBy(sortKey, order));
};

export const trackToggledFilters = (
  categoryFiltered: string,
  filterType: keyof FilterState,
  filterValue: string,
  appliedFilters: FilterState,
  merchant: string
) => {
  const isSelected = appliedFilters[filterType].includes(filterValue);

  track(`Filter ${isSelected ? 'Unselected' : 'Selected'}`, {
    category_filtered: categoryFiltered,
    customer_type: 'new',
    filter_type: filterType,
    filter_value: filterValue,
    merchant,
  });
};

export const trackClearAllFilters = (merchant: string) => {
  track('Filters Cleared', {
    customer_type: 'new',
    merchant,
  });
};

export const trackSortOptionSelected = (sortType: SortType) => {
  track('Sort Selected', {
    customer_type: 'new',
    sort_type: sortType,
  });
};

export const isSelected = (filterType: (string | null)[], item: string) => (
  filterType.some(filter => filter?.toLowerCase() === item.toLowerCase())
);
