/**
 * Capitalizes the first letter of a string and converts the rest of the string to lowercase.
 * If the input string is empty or falsy, an empty string is returned.
 *
 * @param inputString - The string to capitalize.
 * @returns The capitalized string.
 */
export const capitalize = (inputString: string): string => {
  if (!inputString) {
    return '';
  }
  return inputString.charAt(0).toUpperCase() + inputString.slice(1).toLowerCase();
};

/**
 * Converts a string to start case.
 *
 * @param inputString - The input string to convert.
 * @returns The converted string in start case.
 */
export const startCase = (inputString: string): string => {
  if (!inputString) {
    return '';
  }

  return inputString.split(' ').map((word) => {
    return word[0].toUpperCase() + word.substring(1);
  }).join(' ');
};

/**
 * Removes specified characters from the beginning and end of a string.
 *
 * @param inputString - The string to trim.
 * @param chars - The characters to remove from the string.
 * @returns The trimmed string.
 */
export const trimChars = (inputString: string, chars: string): string => {
  if (!inputString) {
    return '';
  }
  if (!chars) {
    return inputString;
  }
  return inputString.replace(new RegExp(`^${chars}+|${chars}+$`, 'g'), '');
};

/**
 * Converts a string to camel case.
 *
 * @param inputString - The input string to convert.
 * @returns The converted string in camel case.
 */
export const camelCase = (inputString: string): string => {
  const arr = inputString.match(/[a-z]+|\d+/gi);
  if (!arr) {
    return '';
  }
  return arr.map((m, i) => {
    let low = m.toLowerCase();
    if (i !== 0) {
      low = low.split('').map((s, k) => k === 0 ? s.toUpperCase() : s).join('');
    }
    return low;
  }).join('');
};
